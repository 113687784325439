:root {
  --main-color: #3f7b2b;
  --black-color: #000;
  --white-color: #FFF;
}

body {
  background-color: rgb(16, 20, 24);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
